import { isWebview } from 'constants/environment';
import { pushCriteoBeacon } from 'features/criteo/connector';
import { useCactConsentGroupAccepted } from './useCactConsentGroupAccepted';

export const useCriteoTracking = () => {
  const consentAccepted = useCactConsentGroupAccepted('TARGETING');
  const trackingAllowed = consentAccepted && !isWebview;
  const _pushCriteoBeacon = trackingAllowed ? pushCriteoBeacon : undefined;
  return { pushCriteoBeacon: _pushCriteoBeacon, userHasSufficientConsent: trackingAllowed };
};
