import { ANALYTIC_EVENTS } from '../constants/analytics';
import { ANALYTICS_OPTIONS } from '../constants/checkout';

/**
 * Mediator utility file.
 *
 * @author mellabr
 * @since 1.0
 */

/**
 * Function which creates the action field object
 * @param {string=} step - the current checkout step
 * @param {object=} option - the current checkout option object
 * @param {string=} orderCode - the order code
 * @returns {object} - the action field object
 */
export function getActionField(step, option, orderCode) {
  let actionField = { step };

  if (option && option.selectedOption) {
    if (
      option.optionType &&
      option.optionType === ANALYTICS_OPTIONS.PAYMENT_METHOD
    ) {
      actionField = { ...actionField, paymentMethod: option.selectedOption };
    } else if (
      option.optionType &&
      option.optionType === ANALYTICS_OPTIONS.DELIVERY_METHOD
    ) {
      actionField = { ...actionField, deliveryMethod: option.selectedOption };
    } else {
      actionField = { ...actionField, option: option.selectedOption };
    }
  }

  if (orderCode) {
    actionField = { id: orderCode, ...actionField };
  }

  return actionField;
}

/**
 * Function which subscribes the onClickTracking function to the mediator
 * @param {object} mediator - the mediator
 * @return {undefined}
 */
function onClickTracking(mediator) {
  mediator.subscribe(
    'tracking',
    (
      event,
      data = undefined,
      step = undefined,
      option = undefined,
      list = undefined,
      orderCode = undefined
    ) => {
      const actionField = getActionField(step, option, orderCode);
      global?.dataLayer?.push({ ecommerce: null });

      const gtmData = {};
      gtmData.event = event;

      if (event === ANALYTIC_EVENTS.PRODUCT_CLICK) {
        gtmData.ecommerce = {
          click: {
            products: [data.commerce] || [],
          },
        };
      } else if (event === ANALYTIC_EVENTS.ADD_TO_CART) {
        gtmData.ecommerce = {
          currencyCode: 'EUR',
          add: {
            actionField: list ? { list } : undefined,
            products: [data.commerce] || [],
          },
        };
      } else if (event === ANALYTIC_EVENTS.ADD_TO_CART_PLP) {
        gtmData.ecommerce = {
          currencyCode: 'EUR',
          add: {
            actionField: list ? { list } : undefined,
            products: [data.commerce] || [],
          },
        };
      } else if (event === ANALYTIC_EVENTS.REMOVE_FROM_CART) {
        gtmData.ecommerce = {
          currencyCode: 'EUR',
          remove: {
            products: [data.commerce] || [],
          },
        };
      } else if (event === ANALYTIC_EVENTS.CHECKOUT_CLICK) {
        gtmData.event = 'checkout';
        gtmData.ecommerce = {
          checkout: {
            actionField,
            products: data.commerce || [],
          },
        };
      }
      global?.dataLayer?.push(gtmData);
    }
  );
}

/**
 * Function which subscribes the ImpressionTracking function to the mediator
 * @param {object} mediator - the mediator
 * @returns {undefined}
 */
function impressionTracking(mediator) {
  mediator.subscribe(
    'impressionTracking',
    (
      event,
      data = undefined,
      step = undefined,
      option = undefined,
      orderCode = undefined
    ) => {
      const actionField = getActionField(step, option, orderCode);
      global?.dataLayer?.push({ ecommerce: null });

      const gtmData = { ecommerce: null };

      gtmData.event = event;

      if (event === ANALYTIC_EVENTS.PRODUCT_IMPRESSION) {
        gtmData.ecommerce = {
          currencyCode: 'EUR',
          impressions: data.impressions,
        };
        global?.dataLayer?.push(gtmData);
      } else if (event === 'product-detail-impression') {
        gtmData.ecommerce = {
          detail: {
            products: [data.commerce] || [],
          },
        };
        global?.dataLayer?.push(gtmData);
      } else if (event === 'checkout-impression') {
        gtmData.event = 'checkout';
        gtmData.ecommerce = {
          checkout: {
            actionField,
            products: data.commerce || [],
          },
        };
        global?.dataLayer?.push(gtmData);
      } else if (event === 'purchase-impression') {
        gtmData.event = 'purchase';
        gtmData.ecommerce = data.commerce || [];
        global?.dataLayer?.push(gtmData);
      } else if (event === 'checkout-cart-impression') {
        gtmData.event = 'shoppingbasket';
        gtmData.ecommerce = {
          checkout: {
            products: data.commerce || [],
          },
        };
        global?.dataLayer?.push(gtmData);
      }
    }
  );
}

/**
 * Function which subscribes the criteoTracking function to the mediator
 * @param {object} mediator - the mediator
 * @returns {undefined}
 */
function criteoTracking(mediator) {
  mediator.subscribe('criteoTracking', (event, data = undefined) => {
    global?.dataLayer?.push({ crto: null });

    const gtmData = { crto: null };

    gtmData.event = event;
    gtmData.crto = data;

    global?.dataLayer?.push(gtmData);
  });
}

/**
 * Function which subscribes all analytics tracking functions to the mediator
 * @param {object} mediator - the mediator
 * @return {undefined}
 */
export default function subscribeToMediator(mediator) {
  onClickTracking(mediator);
  impressionTracking(mediator);
  criteoTracking(mediator);
}
