import styled from 'styled-components';
import { color } from 'theme';

export const StyledProductLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &,
  &:link,
  &:visited {
    color: ${color('whisper-500')};
    text-decoration: none;
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
`;
