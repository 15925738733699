const CRITEO_API_BASE_URL = 'https://d.eu.criteo.com/delivery/retailmedia';

const CRITEO_API_ENABLED = process.env.NEXT_PUBLIC_CRITEO_DELIVERY_TRACKING_ENABLED === 'true';

const CRITEO_API_REQ_PARAMS: Record<string, Record<string, string>> = {
  fr: {
    'criteo-partner-id': process.env.CRITEO_FR ?? '',
  },
  nl: {
    'criteo-partner-id': process.env.CRITEO_NL ?? '',
  },
};

const CRITEO_EVENT_TYPES = {
  ADD_TO_CART: 'addToCart',
  TRACK_TRANSACTION: 'trackTransaction',
  VIEW_BASKET: 'viewBasket',
  VIEW_CATEGORY: 'viewCategory',
  VIEW_CUSTOM_CAMPAIGN: 'viewCustomCampaign',
  VIEW_HOME: 'viewHome',
  VIEW_ITEM: 'viewItem',
  VIEW_SEARCH_RESULT: 'viewSearchResult',
} as const;
export type CriteoEventType = (typeof CRITEO_EVENT_TYPES)[keyof typeof CRITEO_EVENT_TYPES];

const CRITEO_COOKIE_KEY = 'criteoUserId';

export { CRITEO_API_BASE_URL, CRITEO_API_ENABLED, CRITEO_API_REQ_PARAMS, CRITEO_COOKIE_KEY, CRITEO_EVENT_TYPES };

export const SPONSORED_PRODUCTS_FIXED_POSITIONS = [4, 8, 9, 12];
