import axios from 'axios';
import {
  CriteoFlagshipItem,
  CriteoPlacement,
  CriteoProduct,
  FlagshipPlacement,
  FlagshipRendering,
  SimpleCriteoProduct,
  SimplePlacement,
} from 'types/Criteo';
import { CRITEO_API_BASE_URL, CRITEO_API_REQ_PARAMS, CRITEO_EVENT_TYPES, CriteoEventType } from '../constants/criteo';

export const criteoClient = axios.create({
  baseURL: CRITEO_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export const getPageId = (eventType: CriteoEventType, isMobile: boolean, isListView = false, campaignId = '') => {
  const view = isListView ? 'List' : '';
  const api = 'Api';
  const device = isMobile ? 'Mobile' : 'Desktop';

  switch (eventType) {
    case CRITEO_EVENT_TYPES.VIEW_CUSTOM_CAMPAIGN:
      return `${CRITEO_EVENT_TYPES.VIEW_CATEGORY}${view}${api}${device}${campaignId}`;
    default:
      return `${eventType}${view}${api}${device}`;
  }
};

export const getResponseProductPlacementFormat = (
  eventType: CriteoEventType,
  isMobile = false,
  isListView = false,
  campaignId = '',
): string => {
  const pageId = getPageId(eventType, isMobile, isListView, campaignId);
  switch (eventType) {
    case CRITEO_EVENT_TYPES.VIEW_CUSTOM_CAMPAIGN:
      return `${pageId}-inGrid${campaignId}`;
    default:
      return `${pageId}-inGrid`;
  }
};

export const getResponseFlagshipPlacementFormat = (eventType: CriteoEventType, isMobile = false) => {
  const pageId = getPageId(eventType, isMobile);

  return `${pageId}-Flagship`;
};

export const genericCriteoParams = (
  eventType: CriteoEventType,
  body: Record<string, string>,
  isMobile: boolean,
  locale: string,
  cookies: Partial<{ [key: string]: string }>,
  isListView = false,
) => {
  if (!locale) throw new Error('Missing locale for Criteo api call');
  const campaignId = body.campaignId;
  return {
    'page-id': getPageId(eventType, isMobile, isListView, campaignId),
    ...(body.pageUid && { 'page-uid': body.pageUid }),
    ...(body.customerId && { 'customer-id': body.customerId }),
    'criteo-partner-id': CRITEO_API_REQ_PARAMS?.[locale]?.['criteo-partner-id'],
    'event-type': eventType,
    'retailer-visitor-id': cookies?.criteoUserId,
  };
};

type GetCategoryParams = {
  category: string;
  filters?: string;
  item: unknown;
  listSize: number;
  pageNumber: number;
};

const getCategoryParams = ({ category, filters, item, listSize, pageNumber }: GetCategoryParams) => {
  if (!category || !item || !listSize) throw new Error('Missing required parameters for Category call');
  return {
    category,
    item,
    'list-size': listSize,
    ...(filters && { filters }),
    ...(pageNumber && { 'page-number': pageNumber }),
  };
};

type GetCustomCampaignParams = {
  category?: string;
  filters?: string;
};

const getCustomCampaignParams = ({ category, filters }: GetCustomCampaignParams) => {
  const eventType = CRITEO_EVENT_TYPES.VIEW_CATEGORY; // CustomCampaign is treated as a category view
  return {
    category,
    'event-type': eventType,
    ...(filters && { filters }),
  };
};

interface GetSearchResultParams {
  filters?: string;
  item: string;
  keywords: string;
  listSize: number;
  pageNumber?: number;
}

const getSearchResultParams = ({ filters, item, keywords, listSize, pageNumber }: GetSearchResultParams) => {
  if (!keywords || !listSize) throw new Error('Missing required parameters for SearchResult call');
  return {
    item,
    keywords,
    'list-size': listSize,
    ...(filters && { filters }),
    ...(pageNumber && { 'page-number': pageNumber }),
  };
};

interface GetItemParams {
  availability: string;
  filters?: string;
  item: string;
  listPrice: number;
  price: number;
}

const getItemParams = ({ availability, filters, item, listPrice, price }: GetItemParams) => {
  if (!item || !price || !listPrice || !availability) {
    throw new Error('Missing required parameters for Item call');
  }

  return {
    availability,
    item,
    'list-price': listPrice,
    price,
    ...(filters && { filters }),
  };
};

interface GetAddToCartParams {
  item: string;
  pageId: string;
  price: number;
  quantity: number;
}

const getAddToCartParams = ({ item, pageId, price, quantity }: GetAddToCartParams) => {
  if (!item || !price || !quantity || !pageId) throw new Error('Missing required parameters for AddToCart call');
  return {
    item,
    'page-id': pageId,
    price,
    quantity,
  };
};

interface GetBasketParams {
  item: string;
  price: number;
  quantity: number;
}

const getBasketParams = ({ item, price, quantity }: GetBasketParams) => {
  if (!item || !price || !quantity) throw new Error('Missing required parameters for Basket call');
  return {
    item,
    price,
    quantity,
  };
};

interface GetTransactionParams {
  item: string;
  price: number;
  quantity: number;
  transactionId: string;
}

const getTransactionParams = ({ item, price, quantity, transactionId }: GetTransactionParams) => {
  if (!item || !price || !quantity || !transactionId) throw new Error('Missing required parameters for Basket call');
  return {
    item,
    price,
    quantity,
    'transaction-id': transactionId,
  };
};

// TODO: Resolve this with a typeguard instead of a typecast
export const specificCriteoParams = (eventType: string, data: unknown) => {
  switch (eventType) {
    case CRITEO_EVENT_TYPES.VIEW_HOME:
      return {};
    case CRITEO_EVENT_TYPES.VIEW_CATEGORY:
      return getCategoryParams(data as GetCategoryParams);
    case CRITEO_EVENT_TYPES.VIEW_SEARCH_RESULT:
      return getSearchResultParams(data as GetSearchResultParams);
    case CRITEO_EVENT_TYPES.VIEW_ITEM:
      return getItemParams(data as GetItemParams);
    case CRITEO_EVENT_TYPES.ADD_TO_CART:
      return getAddToCartParams(data as GetAddToCartParams);
    case CRITEO_EVENT_TYPES.VIEW_BASKET:
      return getBasketParams(data as GetBasketParams);
    case CRITEO_EVENT_TYPES.TRACK_TRANSACTION:
      return getTransactionParams(data as GetTransactionParams);
    case CRITEO_EVENT_TYPES.VIEW_CUSTOM_CAMPAIGN:
      return getCustomCampaignParams(data as GetCustomCampaignParams);
    default:
      return {};
  }
};

export const mapCriteoProductToSimpleProduct = (product: CriteoProduct): SimpleCriteoProduct => ({
  id: product.ProductId,
  legalInfo: {
    behalf: product.behalf,
    dsa_url: product.dsa_url,
    paid: product.paid,
  },
  onClickUrl: product.OnClickBeacon,
  onLoadUrl: product.OnLoadBeacon,
  onViewUrl: product.OnViewBeacon,
  onWishlistUrl: product.OnWishlistBeacon,
});

export const mapSimplePlacement = (placement: CriteoPlacement): SimplePlacement => ({
  format: placement.format,
  items: placement.products?.map((item) => mapCriteoProductToSimpleProduct(item)),
  legalInfo: {
    behalf: placement.behalf,
    dsa_url: placement.dsa_url,
    paid: placement.paid,
  },
  onClickUrl: placement.OnClickBeacon,
  onLoadUrl: placement.OnLoadBeacon,
  onViewUrl: placement.OnViewBeacon,
});

export const mapFlagshipPlacement = (placement: CriteoFlagshipItem): FlagshipPlacement => ({
  ...mapSimplePlacement(placement),
  rendering: mapFlagshipRendering(placement),
});

const mapFlagshipRendering = (data: CriteoFlagshipItem): FlagshipRendering => ({
  backgroundImage: {
    borderColor: data.rendering.border_color,
    desktop: {
      alt: data.rendering.desktop_background_image_alt_text,
      url: data.rendering.desktop_background_image,
    },
    mobile: {
      alt: data.rendering.mobile_background_image_alt_text,
      url: data.rendering.mobile_background_image,
    },
  },
  link: data.rendering.optional_footer_redirect_url
    ? {
        href: data.rendering.optional_footer_redirect_url,
        newTab: data.rendering.optional_footer_redirect_target === '_blank',
      }
    : undefined,
});

export const mapCriteoSponsoredProductsPlacements = (placements: CriteoPlacement[]) => {
  const filteredPlacements = placements.filter((placement) => placement.format === 'sponsored_products');
  const sponsoredProductsPlacements: SimplePlacement[] = [];

  filteredPlacements.forEach((placement) => sponsoredProductsPlacements.push(mapSimplePlacement(placement)));

  return sponsoredProductsPlacements;
};

export const mapCriteoFlagshipPlacements = (placements: CriteoPlacement[]) => {
  const filteredPlacements = placements.filter((placement) => placement.format === 'FS');
  const flagshipPlacements: FlagshipPlacement[] = [];

  filteredPlacements.forEach((placement) => flagshipPlacements.push(mapFlagshipPlacement(placement)));

  return flagshipPlacements;
};
