import PropTypes from 'prop-types';
import React, {
  Children,
  cloneElement,
  Component,
  isValidElement,
} from 'react';
import Mediator from '../../../helpers/Mediator';
import {
  addCriteoImpressionTracker,
  addCriteoProductListImpressionTracker,
  addImpressionTracker,
  addProductImpressionTracker,
  onClickTrackerClick,
} from '../../../helpers/tracking';
import subscribeToMediator from '../../../utils/mediatorUtils';

class AnalyticsWrapper extends Component {
  constructor(props) {
    super(props);

    this._mediator = new Mediator();
    this._previousImpressions = [];
    this._previousEvent = null;
    this._previousCriteoEvent = null;
    this._previousCriteoImpressions = [];

    this.addOnClickTracker = this.addOnClickTracker.bind(this);
    this.addOnImpressionTracker = this.addOnImpressionTracker.bind(this);
    this.addCriteoTracker = this.addCriteoTracker.bind(this);
    this.addPropsToChild = this.addPropsToChild.bind(this);
  }

  /**
   * Subscribes tracking functions to on the mediator
   * @return {undefined}
   */
  componentDidMount() {
    subscribeToMediator(this._mediator);
    this.addOnClickTracker();
    this.addOnImpressionTracker();
    this.addPropsToChild();
  }

  /**
   * Subscribes Impression tracking function to the mediator when the component updated
   * @return {undefined}
   */
  componentDidUpdate() {
    this.addOnImpressionTracker();
  }

  /**
   * Function that adds a click event to the html tags with the data-analytics-event property
   * with value productClick
   * @return {undefined}
   */
  addOnClickTracker() {
    const { optionType } = this.props;
    const elements = document.querySelectorAll(
      '[data-analytics-event$="-click"]'
    );
    const elementsArr = [].slice.call(elements); // IE
    elementsArr.forEach((el) => {
      if (!el.onclick) {
        el.onclick = () => onClickTrackerClick(el, this._mediator, optionType);
      }
    });
  }

  /**
   * Function which adds the impression tracker to the wrapped component
   * @return {undefined}
   */
  addOnImpressionTracker() {
    const { user } = this.props;
    if (this.props.withImpressions) {
      this._previousImpressions = addProductImpressionTracker(
        this._mediator,
        this._previousImpressions
      );
      this._previousCriteoImpressions = addCriteoProductListImpressionTracker(
        this._mediator,
        this._previousCriteoImpressions,
        user && user.uid
      );
      this.addImpressionTracker();
      this.addCriteoTracker();
    }
  }

  /**
   * Function which adds the product detail impression tracker to the wrapped component
   * @return {undefined}
   */
  addImpressionTracker() {
    const { optionType } = this.props;
    const elements = document.querySelectorAll(
      '[data-analytics-event$="-impression"]'
    );
    const elementsArr = [].slice.call(elements); // IE
    elementsArr.forEach((el) => {
      this._previousEvent = addImpressionTracker(
        el,
        this._mediator,
        this._previousEvent,
        optionType
      );
    });
  }

  /**
   * Function which adds the criteo impression tracker to the wrapper component
   * @returns {undefined}
   */
  addCriteoTracker() {
    const { user } = this.props;
    const elements = document.querySelectorAll('[data-criteo-event^="crto_"]');
    const elementsArr = [].slice.call(elements); // IE
    elementsArr.forEach((el) => {
      this._previousCriteoEvent = addCriteoImpressionTracker(
        el,
        this._previousCriteoEvent,
        this._mediator,
        user && user.uid
      );
    });
  }

  addPropsToChild() {
    const { children, ...otherProps } = this.props;

    const propForData = Object.fromEntries(
      Object.entries(otherProps).filter(([key]) => key.includes('data-'))
    );

    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, { ...propForData });
      }

      return child;
    });
  }

  /**
   * Function that renders the Analytics wrapper component
   * @return {jsx} returns the analytics wrapper component
   */
  render() {
    const { className } = this.props;

    return className ? (
      <div className={className}>{this.addPropsToChild()}</div>
    ) : (
      <>{this.addPropsToChild()}</>
    );
  }
}

AnalyticsWrapper.defaultProps = {
  withImpressions: false,
  optionType: '',
  className: null,
  user: null,
  otherProps: {},
};

AnalyticsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  withImpressions: PropTypes.bool,
  optionType: PropTypes.string,
  user: PropTypes.object,
  className: PropTypes.string,
  otherProps: PropTypes.object,
};

export default AnalyticsWrapper;
