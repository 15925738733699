import {
  FlexContent,
  FlexItem,
  FontType,
  Border,
  Spacer,
  FlexBasis,
  FlexDirection,
  FlexWrap,
  Position,
} from 'constants/styling';
import styled from 'styled-components';
import {
  marginMapper,
  MarginProps,
  paddingMapper,
  PaddingProps,
  positionMapper,
  PositionProps,
  spacer,
  variable,
} from '../../theme';

export interface StyledFlexProps
  extends MarginProps,
    PaddingProps,
    PositionProps {
  alignContent?: FlexContent;
  alignItems?: FlexItem;
  alignSelf?: FlexItem;
  as?: FontType;
  border?: Border;
  borderRadius?: Spacer;
  flex?: string;
  flexBasis?: FlexBasis;
  flexDirection?: FlexDirection;
  flexGrow?: number;
  flexShrink?: number;
  flexWrap?: FlexWrap;
  fullHeight?: boolean;
  fullWidth?: boolean;
  gap?: Spacer;
  justifyContent?: FlexContent;
  justifyItems?: FlexContent;
  justifySelf?: FlexItem;
}

export const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;

  ${marginMapper}
  ${paddingMapper}
  ${positionMapper}

  align-content: ${({ alignContent }) => alignContent};
  align-items: ${({ alignItems }) => alignItems};
  align-self: ${({ alignSelf }) => alignSelf};
  flex: ${({ flex }) => flex};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  height: ${({ fullHeight }) => fullHeight && '100%'};
  width: ${({ fullWidth }) => fullWidth && '100%'};
  gap: ${({ gap }) => spacer(gap)};
  justify-content: ${({ justifyContent }) => justifyContent};
  justify-content: ${({ justifyContent }) => justifyContent};
  justify-items: ${({ justifyItems }) => justifyItems};
  justify-self: ${({ justifySelf }) => justifySelf};
  border-radius: ${({ borderRadius }) => spacer(borderRadius)};

  ${({ border }) => border === 'default' && variable('border')};
  ${({ border }) => border === 'dashed' && variable('border-dashed')};
`;
