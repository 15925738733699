/* eslint-disable no-bitwise */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

/**
 * The Mediator used for analytics
 *
 * Partially used from https://github.com/ajacksified/Mediator.js.
 */

/**
 * Generates a guid.
 *
 * @returns {string} Generated guid.
 */
// eslint-disable-next-line max-classes-per-file
function guidGenerator() {
  const S4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}

/**
 * The subscriber class that is used for listening on a channel.
 *
 * @author janssco
 */
class Subscriber {
  /**
   * The constructor for the Subscriber class.
   *
   * @param {function} - The callback function for the subscriber.
   * @param {object} options - The extra options.
   * @param {object} context - The subscriber context.
   * @constructor
   */
  constructor(fn, options, context) {
    this.id = guidGenerator();
    this.fn = fn;
    this.options = options;
    this.context = context;
    this.channel = null;

    this.update = this.update.bind(this);
  }

  /**
   * Updates the subscriber.
   *
   * @param {object} options - The subscriber options.
   */
  update(options) {
    if (options) {
      this.fn = options.fn || this.fn;
      this.context = options.context || this.context;
      this.options = options.options || this.options;
      if (this.channel && this.options && this.options.priority !== undefined) {
        this.channel.setPriority(this.id, this.options.priority);
      }
    }
  }
}

/**
 * The channel class.
 *
 * @author janssco
 */
class Channel {
  /**
   * The constructor for the channel class.
   *
   * @param {string} namespace - The channel namespace.
   * @param {object} parent - The channel parent.
   * @constructor
   */
  constructor(namespace, parent) {
    this.namespace = namespace || '';
    this._subscribers = [];
    this._channels = {};
    this._parent = parent;
    this.stopped = false;

    this.addSubscriber = this.addSubscriber.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
    this.getSubscriber = this.getSubscriber.bind(this);
    this.setPriority = this.setPriority.bind(this);
    this.hasChannel = this.hasChannel.bind(this);
    this.returnChannel = this.returnChannel.bind(this);
    this.removeSubscriber = this.removeSubscriber.bind(this);
    this.publish = this.publish.bind(this);
  }

  /**
   * Adds a subscriber to the channel.
   *
   * @param {function} fn - The callback function.
   * @param {object} options - The extra options.
   * @param {object} context - The subscriber context.
   * @returns {Subscriber} The added subscriber.
   */
  addSubscriber(fn, options, context) {
    const subscriber = new Subscriber(fn, options, context);

    if (options && options.priority !== undefined) {
      // Cheap hack to either parse as an int or turn it into 0. Runs faster
      // in many browsers than parseInt with the benefit that it won't
      // return a NaN.

      options.priority >= 0;

      if (options.priority < 0) {
        options.priority = 0;
      }
      if (options.priority >= this._subscribers.length) {
        options.priority = this._subscribers.length - 1;
      }

      this._subscribers.splice(options.priority, 0, subscriber);
    } else {
      this._subscribers.push(subscriber);
    }

    subscriber.channel = this;

    return subscriber;
  }

  /**
   * The channel instance is passed as an argument to the mediator subscriber,
   * and further subscriber propagation can be called with channel.StopPropagation().
   *
   * @returns {undefined}
   */
  stopPropagation() {
    this.stopped = true;
  }

  /**
   * Returns a subscriber by identifier.
   *
   * @param {string} identifier - The subscriber identifier.
   * @returns {Subscriber|undefined} The found subscriber or undefined if none found.
   */
  getSubscriber(identifier) {
    let x = 0;
    const y = this._subscribers.length;

    for (x, y; x < y; x += 1) {
      if (
        this._subscribers[x].id === identifier ||
        this._subscribers[x].fn === identifier
      ) {
        return this._subscribers[x];
      }
    }
  }

  /**
   * Channel.setPriority is useful in updating the order in which Subscribers
   * are called, and takes an identifier (subscriber id or named function) and
   * an array index. It will not search recursively through subchannels.
   *
   * @param {string} identifier - The subscriber identifier.
   * @param {number} priority - The subscriber priority.
   * @returns {undefined}
   */
  setPriority(identifier, priority) {
    let oldIndex = 0;
    let x = 0;
    let y;

    for (x = 0, y = this._subscribers.length; x < y; x += 1) {
      if (
        this._subscribers[x].id === identifier ||
        this._subscribers[x].fn === identifier
      ) {
        break;
      }
      oldIndex += 1;
    }

    const sub = this._subscribers[oldIndex];
    const firstHalf = this._subscribers.slice(0, oldIndex);
    const lastHalf = this._subscribers.slice(oldIndex + 1);

    this._subscribers = firstHalf.concat(lastHalf);
    this._subscribers.splice(priority, 0, sub);
  }

  /**
   * Adds a channel.
   *
   * @param {string} channel - The channel identifier.
   * @returns {undefined}
   */
  addChannel(channel) {
    this._channels[channel] = new Channel(
      (this.namespace ? `${this.namespace}:` : '') + channel,
      this
    );
  }

  /**
   * Checks whether a channel has already been added..
   *
   * @param {string} channel - The channel identifier.
   * @returns {boolean} Flag if channel is already added.
   */
  hasChannel(channel) {
    return this._channels.hasOwnProperty(channel);
  }

  /**
   * Retrieves a channel.
   *
   * @param {string} channel - The channel identifier.
   * @returns {Channel|undefined} The found channel or undefined.
   */
  returnChannel(channel) {
    return this._channels[channel];
  }

  /**
   * Removes a subscriber from the channel.
   *
   * @param {string} identifier - The subscriber identifier.
   * @returns {undefined}
   */
  removeSubscriber(identifier) {
    let x = this._subscribers.length - 1;

    // If we don't pass in an id, we're clearing all
    if (!identifier) {
      this._subscribers = [];
      return;
    }

    // Going backwards makes splicing a whole lot easier.
    for (x; x >= 0; x -= 1) {
      if (
        this._subscribers[x].fn === identifier ||
        this._subscribers[x].id === identifier
      ) {
        this._subscribers[x].channel = null;
        this._subscribers.splice(x, 1);
      }
    }
  }

  /**
   * This will publish arbitrary arguments to a subscriber and then to parent channels.
   *
   * @param {object} data - The data to publish.
   * @returns {undefined}
   */
  publish(data) {
    let x = 0;
    let y = this._subscribers.length;
    let shouldCall = false;
    let subscriber;
    let subsBefore;
    let subsAfter;

    // Priority is preserved in the _subscribers index.
    for (x, y; x < y; x += 1) {
      // By default set the flag to false
      shouldCall = false;
      subscriber = this._subscribers[x];

      if (!this.stopped) {
        subsBefore = this._subscribers.length;
        if (
          subscriber.options !== undefined &&
          typeof subscriber.options.predicate === 'function'
        ) {
          if (subscriber.options.predicate.apply(subscriber.context, data)) {
            // The predicate matches, the callback function should be called
            shouldCall = true;
          }
        } else {
          // There is no predicate to match, the callback should always be called
          shouldCall = true;
        }
      }

      // Check if the callback should be called
      if (shouldCall) {
        // Check if the subscriber has options and if this include the calls options
        if (subscriber.options && subscriber.options.calls !== undefined) {
          // Decrease the number of calls left by one
          subscriber.options.calls -= 1;
          // Once the number of calls left reaches zero or less we need to remove the subscriber
          if (subscriber.options.calls < 1) {
            this.removeSubscriber(subscriber.id);
          }
        }
        // Now we call the callback, if this in turns publishes to the same channel it will no longer
        // cause the callback to be called as we just removed it as a subscriber
        subscriber.fn.apply(subscriber.context, data);

        subsAfter = this._subscribers.length;
        y = subsAfter;
        if (subsAfter === subsBefore - 1) {
          x -= 1;
        }
      }
    }

    if (this._parent) {
      this._parent.publish(data);
    }

    this.stopped = false;
  }
}

/**
 * The mediator class.
 * A Mediator instance is the interface through which events are registered and removed from publish channels.
 *
 * @author janssco
 */
export default class Mediator {
  /**
   * The consctructor for the Mediator class.
   *
   * @constructor.
   */
  constructor() {
    this._channels = new Channel('');

    this.getChannel = this.getChannel.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.getSubscriber = this.getSubscriber.bind(this);
    this.remove = this.remove.bind(this);
    this.publish = this.publish.bind(this);
  }

  /**
   * Returns a channel instance based on namespace, for example application:chat:message:received.
   * If readOnly is true we will refrain from creating non existing channels.
   *
   * @param {string} namespace - The channel namespace.
   * @param {boolean} readOnly - If the channel is read only or not.
   * @returns {Channel|undefined} the found channel or undefined.
   */
  getChannel(namespace, readOnly) {
    let channel = this._channels;
    const namespaceHierarchy = namespace.split(':');
    let x = 0;
    const y = namespaceHierarchy.length;

    if (namespace === '') {
      return channel;
    }

    if (namespaceHierarchy.length > 0) {
      for (x, y; x < y; x += 1) {
        if (!channel.hasChannel(namespaceHierarchy[x])) {
          if (readOnly) {
            break;
          } else {
            channel.addChannel(namespaceHierarchy[x]);
          }
        }

        channel = channel.returnChannel(namespaceHierarchy[x]);
      }
    }

    return channel;
  }

  /**
   * Pass in a channel namespace, function to be called, options, and context to call the function in to Subscribe.
   * It will create a channel if one does not exist. Options can include a predicate to determine if it should be called (based on the data published to it) and a priority index
   *
   * @param {string} channelName - The channel name.
   * @param {function} fn - The callback to execute.
   * @param {object} options - The extra options.
   * @param {object} context - The context to call the callback in.
   * @returns {Subscriber} The created subscriber.
   */
  subscribe(channelName, fn, options, context) {
    const channel = this.getChannel(channelName || '', false);

    options = options || {};
    context = context || {};

    return channel.addSubscriber(fn, options, context);
  }

  /**
   * Pass in a channel namespace, function to be called, options, and context to call the function in to Subscribe.
   * It will create a channel if one does not exist. Options can include a predicate to determine if it should be called (based on the data published to it) and a priority index.
   *
   * @param {string} channelName - The channel name.
   * @param {function} fn - The callback to execute.
   * @param {object} options - The extra options.
   * @param {object} context - The context to call the callback in.
   * @returns {Subscriber} The created subscriber.
   */
  once(channelName, fn, options, context) {
    options = options || {};
    options.calls = 1;

    return this.subscribe(channelName, fn, options, context);
  }

  /**
   * Returns a subscriber for a given subscriber id / named function and channel namespace.
   *
   * @param {string} identifier - The subscriber identifier.
   * @param {string} channelName - The channel name.
   * @returns {Subscriber|null} The found subscriber for the channel or null.
   */
  getSubscriber(identifier, channelName) {
    const channel = this.getChannel(channelName || '', true);
    // We have to check if channel within the hierarchy exists and if it is
    // an exact match for the requested channel
    if (channel.namespace !== channelName) {
      return null;
    }

    return channel.getSubscriber(identifier);
  }

  /**
   * Remove a subscriber from a given channel namespace recursively based on a passed-in subscriber id or named function.
   *
   * @param {string} channelName - The channel name.
   * @param {string} identifier - The subscriber identifier,
   * @returns {boolean|undefined} False if channel was not found, else undefined.
   */
  remove(channelName, identifier) {
    const channel = this.getChannel(channelName || '', true);
    if (channel.namespace !== channelName) {
      return false;
    }

    channel.removeSubscriber(identifier);
  }

  /**
   * Publishes arbitrary data to a given channel namespace. Channels are called recursively downwards
   * A post to application:chat will post to application:chat:receive and application:chat:derp:test:beta:bananas.
   *
   * Called using Mediator.publish("application:chat", [ args ]);
   *
   * @param {string} channelName - The channel name.
   * @returns {null|undefined} Returns null of the channel was not found, else undefined.
   */
  publish(channelName) {
    const channel = this.getChannel(channelName || '', true);
    if (channel.namespace !== channelName) {
      return null;
    }

    const args = Array.prototype.slice.call(arguments, 1);

    args.push(channel);

    channel.publish(args);
  }
}
