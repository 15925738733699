import { AxiosResponse } from 'axios';
import { CRITEO_EVENT_TYPES } from 'constants/criteo';
import { getPageId } from 'helpers/CriteoClient';
import { nextApiClient } from 'helpers/NextApiClient';
import { Cart } from 'types/Cart';
import { CriteoEventResponse } from 'types/Criteo';
import { Order } from 'types/Order';
import { normalizeCartEntries, reduceNormalizedCartEntry } from 'utils/criteoUtil';
import { log } from 'utils/loggerUtil';
import { isMobile } from 'utils/screenUtils';
import { CRITEO_API_ENABLED, CriteoEventType } from '../../constants/criteo';

type BaseConfig = {
  isMobile: boolean;
};

export type HomeConfig = BaseConfig & {
  eventType: 'viewHome';
};

export type CustomCampaignConfig = BaseConfig & {
  campaignId?: string;
  category?: string;
  eventType: 'viewCustomCampaign';
  filters?: string;
};

export type CategoryConfig = BaseConfig & {
  category: string;
  eventType: 'viewCategory';
  /**
   * Use the mapSolrQueryToCriteoFilters or mapAlgoliaRefinementsToCriteoFilters functions to convert the query correctly.
   */
  filters?: string;
  isListView?: boolean;
  /**
   * String of product codes concatenated with a pipe
   */
  item: string;
  listSize: number;
  pageNumber: number;
};

export type ItemConfig = BaseConfig & {
  availability: 1 | 0;
  eventType: 'viewItem';
  /**
   * Use the mapSolrQueryToCriteoFilters or mapAlgoliaRefinementsToCriteoFilters functions to convert the query correctly.
   */
  filters?: string;
  /**
   * String of product codes concatenated with a pipe
   */
  item: string;
  listPrice: number;
  price: number;
};

/**
 * item: property which is a list of product codes concatenated with a pipe
 * filters: string of filters
 */
export type SearchConfig = {
  eventType: 'viewSearchResult';
  /**
   * Use the mapSolrQueryToCriteoFilters or mapAlgoliaRefinementsToCriteoFilters functions to convert the query correctly.
   */
  filters?: string;
  isListView?: boolean;
  isMobile: boolean;
  /**
   * String of product codes concatenated with a pipe
   */
  item: string;
  keywords: string;
  listSize: number;
  pageNumber: number;
};

export type CriteoEventConfig = HomeConfig | CustomCampaignConfig | CategoryConfig | ItemConfig | SearchConfig;

const pushViewEvent = async (config: CriteoEventConfig) => {
  if (!CRITEO_API_ENABLED) return;

  try {
    const { eventType, ...eventData } = config;

    const { data } = (await nextApiClient({
      data: eventData,
      method: 'POST',
      url: `/criteo/${eventType}`,
    })) as AxiosResponse<CriteoEventResponse>;

    return data;
  } catch (error) {
    log('Criteo pushViewEvent', 'Something went wrong pushing view analytics', error);
  }
};

const pushViewBasketEvent = async (cart: Cart) => {
  if (!CRITEO_API_ENABLED) return;

  const normalizedCartEntries = cart.entries ? normalizeCartEntries(cart.entries) : [];
  const { item, price, quantity } = normalizedCartEntries.reduce(reduceNormalizedCartEntry, {
    item: '',
    price: '',
    quantity: '',
  });

  try {
    const requestData = {
      isMobile: isMobile(),
      item,
      price,
      quantity,
    };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.VIEW_BASKET}`,
    });
  } catch (error) {
    log('Criteo pushViewBasketEvent', 'Something went wrong pushing viewBasket analytics', error);
  }
};

interface PushAddToCartEventProps {
  currentPageEvent: CriteoEventType;
  item: string;
  price: number;
  quantity: number;
}

const pushAddToCartEvent = async ({ currentPageEvent, item, price, quantity }: PushAddToCartEventProps) => {
  if (!CRITEO_API_ENABLED) return;
  try {
    const pageId = getPageId(currentPageEvent, isMobile());
    const pageUid = document?.body.getAttribute('data-criteo-page-uid');

    const requestData = {
      isMobile: isMobile(),
      item,
      pageId,
      pageUid,
      price,
      quantity,
    };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.ADD_TO_CART}`,
    });
  } catch (error) {
    log('Criteo pushAddToCartEvent', 'Something went wrong pushing addToCart analytics', error);
  }
};

const pushTrackTransactionEvent = async (order: Order) => {
  if (!CRITEO_API_ENABLED) return;

  const normalizedOrderEntries = order?.entries ? normalizeCartEntries(order.entries) : [];
  const { item, price, quantity } = normalizedOrderEntries.reduce(reduceNormalizedCartEntry, {
    item: '',
    price: '',
    quantity: '',
  });

  const { guid: transactionId } = order;

  try {
    const requestData = {
      isMobile: isMobile(),
      item,
      price,
      quantity,
      transactionId,
    };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.TRACK_TRANSACTION}`,
    });
  } catch (error) {
    log('Criteo pushTrackTransactionEvent', 'Something went wrong pushing addToCart analytics', error);
  }
};

const pushCriteoBeacon = async (beacon: string) => {
  if (!CRITEO_API_ENABLED) return;
  try {
    if (!beacon) throw new Error('No beacon passed to push');

    const beaconUrl = `${beacon.startsWith('//') ? 'https:' : ''}${beacon}`;
    const requestData = { beacon: beaconUrl };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/beacon`,
    });
  } catch (error) {
    log('Criteo pushCriteoBeacon', 'Something went wrong pushing beacon analytics', error);
  }
};

export { pushAddToCartEvent, pushCriteoBeacon, pushTrackTransactionEvent, pushViewBasketEvent, pushViewEvent };
