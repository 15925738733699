import { HTMLAttributes, ReactNode } from 'react';
import { StyledFlex, StyledFlexProps } from './Flex.styled';

export interface FlexProps
  extends StyledFlexProps,
    HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}
const Flex = ({ children, ...props }: FlexProps) => (
  <StyledFlex {...props}>{children}</StyledFlex>
);

export default Flex;
