import { pathnames } from 'i18n/pathnames';
import { ReactNode } from 'react';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

interface StoreLinkProps {
  children: ReactNode;
  storeName: string;
}

const StoreLink = ({ children, storeName }: StoreLinkProps) => (
  <LocalizedLink name={pathnames.STOREFINDER} options={{ query: { id: storeName } }}>
    {children}
  </LocalizedLink>
);

export default StoreLink;
