import React from 'react';
import Link from '../../../Link/Link';
import { Category } from 'types/Category';

interface CategoryLinkProps {
  category: Category;
  children: React.ReactNode;
  className?: string;
  clickable?: boolean;
  onClick?: () => void;
}

const CategoryLink = ({ category, children, className, clickable, onClick }: CategoryLinkProps) => {
  if (clickable) {
    return (
      <Link className={className} onClick={onClick} url={category.url}>
        {children}
      </Link>
    );
  }
  return <span className={className}>{children}</span>;
};

export default CategoryLink;
