/**
 * Helper for tracking info.
 * todo: move to utility file OR refactor to helper.
 */

import { ANALYTIC_EVENTS } from '../constants/analytics';

/**
 * Function which formats the string html attribute to an json object
 * @param {string} attribute - the html attribute
 * @return {undefined}
 */
export function getObjectFromAttribute(attribute) {
  return JSON.parse(attribute);
}

/**
 * Function which checks if two arrays are equal
 * @param {Array} arr1 - the first array
 * @param {Array} arr2 - the second array
 * @return {boolean} returns true is both array are equal or false if they are not equal
 */
export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let index = 0; index < arr1.length; index += 1) {
    const el = arr1[index];

    const found = arr2.find((el2) => el.id === el2.id);

    if (!found) {
      return false;
    }
  }

  return true;
}

/**
 * Function which returns the criteo formatted products array
 * @param {Array|undefined} products - the products array
 * @returns {Array|undefined} the criteo formatted products array
 */
export default function getCriteoProductsArray(products) {
  if (products && products.length > 0) {
    const criteoProducts = [];
    products.forEach((product) => {
      criteoProducts.push({
        id: product.id,
        price: product.price,
        quantity: product.quantity || 1,
      });
    });
    return criteoProducts;
  }

  return null;
}

/**
 * Function which pushes product data to the dataLayer
 * @param {object} el - the html element with the data-analytics-event attribute
 * @param {object} mediator - the mediator
 * @param {string=} optionType - the option type
 * @return {undefined}
 */
export function onClickTrackerClick(el, mediator, optionType) {
  const event = el.getAttribute('data-analytics-event')
    ? el.getAttribute('data-analytics-event')
    : undefined;
  const commerce = el.getAttribute('data-tracking-commerce')
    ? getObjectFromAttribute(el.getAttribute('data-tracking-commerce'))
    : undefined;
  const list = el.getAttribute('data-tracking-list')
    ? el.getAttribute('data-tracking-list')
    : undefined;
  const orderCode = el.getAttribute('data-tracking-order-code')
    ? el.getAttribute('data-tracking-order-code')
    : undefined;

  if (
    event === ANALYTIC_EVENTS.CHECKOUT_CLICK ||
    event === ANALYTIC_EVENTS.CHECKOUT_CART_CLICK
  ) {
    const step = el.getAttribute('data-tracking-step')
      ? el.getAttribute('data-tracking-step')
      : undefined;
    const option = {
      selectedOption: el.getAttribute('data-tracking-option')
        ? el.getAttribute('data-tracking-option')
        : undefined,
      optionType,
    };
    mediator.publish(
      'tracking',
      event,
      { commerce },
      step,
      option,
      undefined,
      orderCode
    );
  } else {
    mediator.publish(
      'tracking',
      event,
      { commerce },
      undefined,
      undefined,
      list
    );
  }
}

/**
 * Function which pushes all product data showed on the search page to the dataLayer
 * @param {object} mediator - the mediator
 * @param {Array} previousImpressions - the previous impression list with product data
 * @return {Array} returns the current impression list
 */
export function addProductImpressionTracker(mediator, previousImpressions) {
  const impressions = [];
  const elements = document.querySelectorAll(
    '[data-analytics-event="product-impression"]'
  );
  const elementsArr = [].slice.call(elements); // IE

  elementsArr.forEach((el) => {
    const data = el.getAttribute('data-tracking-commerce')
      ? getObjectFromAttribute(el.getAttribute('data-tracking-commerce'))
      : undefined;
    if (data.id) {
      impressions.push(data);
    }
  });

  if (elements.length > 0 && !arraysEqual(impressions, previousImpressions)) {
    mediator.publish('impressionTracking', ANALYTIC_EVENTS.PRODUCT_IMPRESSION, {
      impressions,
    });
  }

  return impressions;
}

/**
 * Function which pushes product detail information to the dataLayer
 * @param {object} el - the html element with the data-analytics-event attribute
 * @param {object} mediator - the mediator
 * @param {string} previousEvent - the previous event
 * @param {string=} optionType - the option type
 * @returns {string} the current event
 */
export function addImpressionTracker(el, mediator, previousEvent, optionType) {
  const event = el.getAttribute('data-analytics-event')
    ? el.getAttribute('data-analytics-event')
    : undefined;
  const commerce = el.getAttribute('data-tracking-commerce')
    ? getObjectFromAttribute(el.getAttribute('data-tracking-commerce'))
    : undefined;

  if (event === 'checkout-impression') {
    const step = el.getAttribute('data-tracking-step')
      ? el.getAttribute('data-tracking-step')
      : undefined;
    const option = {
      selectedOption: el.getAttribute('data-tracking-option')
        ? el.getAttribute('data-tracking-option')
        : undefined,
      optionType,
    };
    const orderCode = el.getAttribute('data-tracking-order-code')
      ? el.getAttribute('data-tracking-order-code')
      : undefined;

    if (event !== previousEvent) {
      mediator.publish(
        'impressionTracking',
        event,
        { commerce },
        step,
        option,
        orderCode
      );
    }
  } else if (
    event === ANALYTIC_EVENTS.PRODUCT_DETAIL_IMPRESSION ||
    event === ANALYTIC_EVENTS.PURCHASE_IMPRESSION ||
    event === ANALYTIC_EVENTS.CHECKOUT_CART_IMPRESSION
  ) {
    if (event !== previousEvent) {
      mediator.publish('impressionTracking', event, { commerce });
    }
  }
  return event;
}

/**
 * Function which pushes criteo information to the dataLayer
 * @param {object} el - the html element with the data-analytics-event attribute
 * @param {string} previousEvent - the previous event
 * @param {object} mediator - the mediator
 * @param {string} email - the current user's id
 * @returns {string} the criteo event
 */
export function addCriteoImpressionTracker(el, previousEvent, mediator, email) {
  const crto = {};
  crto.email = email || '';

  const event = el.getAttribute('data-criteo-event')
    ? el.getAttribute('data-criteo-event')
    : undefined;
  const data = el.getAttribute('data-tracking-commerce')
    ? getObjectFromAttribute(el.getAttribute('data-tracking-commerce'))
    : undefined;

  if (event !== previousEvent) {
    if (event === 'crto_productpage') {
      if (data && data.id) {
        crto.products = [data.id];
        mediator.publish('criteoTracking', event, crto);
      }
    } else if (event === 'crto_basketpage' && data?.constructor === Array) {
      crto.products = getCriteoProductsArray(data);
      mediator.publish('criteoTracking', event, crto);
    } else if (event === 'crto_transactionpage') {
      crto.transactionid =
        data &&
        data.purchase &&
        data.purchase.actionField &&
        data.purchase.actionField.id;
      crto.products = getCriteoProductsArray(data.purchase.products);
      mediator.publish('criteoTracking', event, crto);
    }
  }

  return event;
}

/**
 * Function which pushes criteo product list information to the dataLayer
 * @param {object} mediator - the mediator
 * @param {Array} previousImpressions - the previous impression list with product data
 * @param {string=} email - the user 's id.
 * @returns {Array} returns the current impression list
 */
export function addCriteoProductListImpressionTracker(
  mediator,
  previousImpressions,
  email
) {
  const impressions = [];
  const crto = {};
  crto.email = email || '';

  const elements = document.querySelectorAll(
    '[data-criteo-event="crto_listingpage"]'
  );
  const elementsArr = [].slice.call(elements); // IE

  elementsArr.forEach((el) => {
    const data = el.getAttribute('data-tracking-commerce')
      ? getObjectFromAttribute(el.getAttribute('data-tracking-commerce'))
      : undefined;
    if (data.id) {
      impressions.push(data.id);
    }
  });

  crto.products = impressions;

  if (elements.length > 0 && !arraysEqual(impressions, previousImpressions)) {
    mediator.publish('criteoTracking', 'crto_listingpage', crto);
  }

  return impressions;
}
